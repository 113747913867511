import { Box, Grid, VStack } from '@chakra-ui/layout';
import Heading from '@components/Heading';
import dynamic from 'next/dynamic';
const Container = dynamic(
  () => import('@components/LayoutStructure/Container')
);
import { HeadingElements } from '~/@types/heading';
import { headingSizes } from '~/config/headings';
import { MagnoliaImage, Metadata } from '~/types/Magnolia';
import AvatarCard from './AvatarCard';
import { getColor } from '@utils/getColor';

export interface Avatar extends Metadata {
  description: string;
  name: string;
  role: string;
  switchable: {
    field: string;
    image?: MagnoliaImage;
    imageAlt?: string;
    imageUrl?: string;
  };
}

export interface Avatars extends Metadata {
  [key: string]: string | Array<string> | Avatar | undefined;
}

interface AvatarTilesProps {
  avatars?: Avatars;
  cardColor: {
    color: string;
    range: string;
  };
  textColor: {
    color: string;
    range: string;
  };
  showAvatar: boolean;
  description?: string;
  headingElement?: HeadingElements;
  title?: string;
  titleAlignment: 'left' | 'center' | 'right';
  cardTextAlignment: 'left' | 'center' | 'right';
}

const AvatarTiles: React.FC<AvatarTilesProps> = ({
  avatars,
  cardColor = { color: 'white', range: '900' },
  textColor = { color: 'black', range: '900' },
  showAvatar,
  description,
  headingElement,
  title,
  titleAlignment,
  cardTextAlignment,
}) => {
  const headingSize = headingSizes[headingElement || 'h2'];
  const nodes = avatars
    ? avatars['@nodes']?.map((node) => avatars[node]) ?? []
    : [];
  const columns = nodes.length < 3 ? nodes.length : 3;
  const color = getColor(textColor.color, textColor.range);
  const cardBackgroundColor = getColor(cardColor.color, cardColor.range);
  return (
    <Container className="avatar-container">
      <VStack align={titleAlignment} gap={3}>
        {title && (
          <Heading
            headingElement={headingElement}
            headingSize={headingSize}
            title={title}
            withContainer={false}
            textAlign="center"
            color={color}
          />
        )}

        {description && (
          <Box
            color={color}
            fontSize="lg"
            textAlign="center"
            className="magnolia-text"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </VStack>

      <Grid
        autoColumns="1fr"
        autoRows="1fr"
        columnGap={5}
        my="44px"
        rowGap="10"
        templateColumns={{
          base: 'minmax(0, 1fr)',
          lg: `repeat(${columns}, minmax(0, 1fr))`,
        }}
        textAlign={cardTextAlignment}
      >
        {nodes.map((card) => {
          const {
            '@id': id,
            name,
            role,
            description,
            switchable,
          } = card as Avatar;
          return (
            <AvatarCard
              key={id}
              textColor={color}
              cardColor={cardBackgroundColor}
              description={description}
              name={name}
              role={role}
              showAvatar={showAvatar}
              switchable={switchable}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default AvatarTiles;
