import { Avatar } from '@chakra-ui/avatar';
import { Card, CardBody } from '@chakra-ui/card';
import { Text, Box } from '@chakra-ui/layout';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { IMAGE_DOMAINS } from '~/constants';
import { MagnoliaImage } from '~/types/Magnolia';
const DAMImage = dynamic(() => import('@components/Image/DAMImage'));

interface AvatarCardProps {
  cardColor: string;
  textColor: string;
  description?: string;
  name: string;
  role: string;
  showAvatar: boolean;
  switchable: {
    field: string;
    image?: MagnoliaImage;
    imageAlt?: string;
    imageUrl?: string;
  };
}

const renderAvatar = (switchable, name) => {
  const { field, image, imageAlt, imageUrl } = switchable || {};
  const imageSize = 74;
  const defaultAvatar = <Avatar name={name} h={imageSize} w={imageSize} />;
  switch (field) {
    case 'damChooser':
      return (
        <DAMImage
          src={image}
          style={{
            margin: 'auto',
            width: '161px',
            height: '181px',
            objectFit: 'cover',
            objectPosition: 'center',
            borderRadius: '16px',
          }}
        />
      );
    case 'externalSource':
      const hasValidHost = IMAGE_DOMAINS.some((url) => imageUrl?.includes(url));
      if (!hasValidHost) {
        console.error('Invalid host in image url');
      }
      return hasValidHost ? (
        <Image
          src={imageUrl}
          alt={imageAlt}
          fill
          style={{
            margin: 'auto',
            width: '161px',
            height: '181px',
            borderRadius: '16px',
            objectFit: 'cover',
            objectPosition: 'center',
          }}
        />
      ) : (
        defaultAvatar
      );
    default:
      return defaultAvatar;
  }
};

const AvatarCard: React.FC<AvatarCardProps> = ({
  cardColor = 'white',
  textColor = 'black',
  description,
  name,
  role,
  switchable,
  showAvatar = false,
}) => {
  return (
    <Card
      background="gray.50"
      boxShadow="lg"
      rounded="xl"
      color={textColor}
      backgroundColor={cardColor}
      padding={3.5}
      direction={{ base: 'column', sm: 'row', lg: 'column' }}
    >
      {showAvatar && <>{renderAvatar(switchable, name)}</>}
      <CardBody lineHeight={1.2}>
        <Text
          fontSize="3xl"
          fontWeight="700"
          mt={showAvatar ? { base: '6', sm: '0', lg: '6' } : 0}
        >
          {name}
        </Text>
        <Text fontWeight="700" mt="2" fontSize="md">
          {role}
        </Text>
        {description && (
          <Box
            fontWeight="400"
            fontSize="sm"
            mt={5}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default AvatarCard;
